import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import JotformEmbed from 'react-jotform-embed';

import { Layout, Block, Header, MetaTags } from '../components'

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown } from '../styles/colors';
import { heading } from '../styles/type';
import themes, { getThemeData } from '../styles/themes';
import { media } from '../styles/utils';


class Apply extends React.Component {

	state = {

	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressApply.acf_json),
            //categories: this.props.data.allWordpressCategories.edges
		})
    }



	render() {       
        const { showForm, activeForm, data } = this.state;

		return (
			<Layout
				// Replace with term slug 
				theme={'residential-architecture'} 
				footerImage={data.footer_bg_image && data.footer_bg_image.sizes.xlarge}
			>	
                <MetaTags
                    title={'Apply'}
                    description={''}
                />
				<Wrapper>
					<Header
						showNav={true}
						showSubmit={true}
						sticky={true}
						theme={{
							colour: brown,
							background: grey
						}}
					/>

                    {/* <Title>Apply</Title> */}

                    {/* <JotformEmbed 
                        src="https://form.jotform.com/211009005023838" 
                    /> */}
                    
				</Wrapper>
			</Layout>	
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Container = styled.div`
	${container};
	${padding};
	flex-direction: column;
	width: 100%;
    background: ${grey};
	padding-top: 100px;
`

const Title = styled.h1`
    ${heading};
    text-transform: uppercase;
    max-width: 520px;
    text-align: center;
    margin-top: 145px;
    margin-bottom: 110px;

	${media.phone`
		margin-top: 96px;
		margin-bottom: 64px;
	`}
`
const Heading = styled.div``
const Text = styled.div``

const Categories = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center; 
    margin-bottom: 140px;

`


const Category = styled.div`
	font-size: 30px;
	letter-spacing: 0.027em;
	color: #54421b;
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	transition: color 0.2s ease;
	
	&:not(:last-of-type) {
		margin-bottom: 30px;

		${media.phone`
			margin-bottom: 25px;
		`}
	}

	/* Hover Theme */

	${props => {
		if (props.theme) return css`
			&:hover {
				color: ${props.theme.text};
			}
		`
	}}
	
	${media.phone`
		font-size: 20px;
	`}
`

export default Apply

export const query = graphql`
	query {
		wordpressApply {
			acf_json	
		}

        # allWordpressCategories {
		# 	edges {
		# 		node {
		# 			title
		# 			slug
		# 			acf_json
		# 		}
		# 	}
		# }
	}
`